
html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    animation: fadeIn 1s ease-in-out;
    background-color: #fcfcfcf6;
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }


header {
  width: 100%;
  height: 150px; 
  display: flex;
  align-items: center; 
  justify-content: space-between; 
  background-color: #000000; 
  padding: 10px; 
  animation: fadeIn 1s ease-in-out;
}

header img {
  max-height: 70%;
  max-width: 70%;
  margin-left: 5%;
  object-fit: contain; 
  animation: slideInFromLeft 1s ease-in-out;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.pie-charts {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 1s forwards; 
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  background-color: #fcfcfcf6;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.keyword-container {
  background-color: white;
  width: 100%;
  height: 800px; 
}


.keyword-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: white; 
  margin-top: 4vh;
  margin-bottom: 4vh;
}

.keyword-text {
  flex: 1; 
  min-width: 40%;
  margin-right: 10%;
}

.keyword {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  margin-bottom: 50px;
  color: #333;
  font-size: 1vw;
  font-weight: bold;
  margin-left: 100px;
}

.keyword.visible {
  opacity: 1;
  transform: translateY(0);
}

.keyword-container .keyword:nth-child(1) {
  transition-delay: 0.2s;
}

.keyword-container .keyword:nth-child(2) {
  transition-delay: 0.4s;
}

.keyword-container .keyword:nth-child(3) {
  transition-delay: 0.6s;
}

.keyword-container .keyword:nth-child(4) {
  transition-delay: 0.8s;
}

.keyword-image {
  right: 0;

}

.keyword-image img {
  max-width: 38vw;
  height: auto;
}



.keyword-highlight-text {
  color: #00b0f0;
  font-size: clamp(1rem, 2vw + 1rem, 3rem);
}



@media (max-width: 1024px) {
  .keyword {
    font-size: 1rem; 
    margin-bottom: 15px; 
  }
}

@media (max-width: 768px) {
  .keyword {
    font-size: 0.9rem; 
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .keyword {
    font-size: 0.8rem; 
    margin-bottom: 5px; 
  }
}

 .rowitems {
  display:flex;
  flex-wrap:wrap;
  flex-direction: row;
  }


  .stitch-image img {
    max-width: 70%;  
    display:flex;
    flex-wrap:wrap;
  }


.design2 {
  filter: blur(5px); 
}


  
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  



.map-container {
  width: 100%;
  overflow: hidden; 
}

.map-container img {
  width: 100%;
  height: auto; 
  display: block;
}






.hero-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; 
}

.hero-image-text-container {
  position: relative;
  width: 100%;
}

.hero-image {
  width: 100%;
  height: 10%;
  object-fit: cover;
  display: block;
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out; 
}







.overlay-text {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f8cc4a;
  text-align: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  max-width: 80%; 
}

.overlay-text {
  font-size: 2vw;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}


.overlay-logo-container {
  position: absolute;
  top: 40%;
  left: 0; 
  transform: translateY(-50%);
  max-width: 55%;
  opacity: 0.8;
}



.overlay-logo {
  max-width: 40vw;
  margin-bottom: 20px;
}


.overlay-text > div {
  font-size: 36px;
  font-weight: bold;
}

  


  
  .container {
    flex: 1; 
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    color: #333;
    font-size: 24px;
    font-weight: bold;
  }
  
  p {
    color: #666;
    font-size: 16px;
  }
  
  footer {
    background-color: #21211f;
    color: white;
    text-align: center;
    padding: 20px 10px; 
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
  }
  
  .footer-section {
    flex: 1;
    min-width: 150px;
    margin: 10px;
    text-align: center;
  }
  
  .footer-section h3 {
    font-size: 1rem; 
    margin-top: 0;
  }
  
  .footer-section p {
    font-size: 0.875rem; 
    margin: 5px 0;
  }
  
  .footer-section a {
    color: #00b0f0;
    text-decoration: none;
  }
  
  .footer-section a:hover {
    text-decoration: underline;
  }
  
  .PP {
    font-size: 0.875rem; /* Adjusted font size */
    margin-top: 10px;
  }
  


/* Media Queries */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Stack sections vertically on very small screens */
  }

  .footer-section {
    flex: none;
    width: 100%; 
    margin: 5px 0; 
  }


  .PP {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .footer-section h3 {
    font-size: 1rem; 
  }

  .footer-section p {
    font-size: 0.9rem; 
  }

  .PP {
    font-size: 0.9rem;
  }
}


  .sectionTitle {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px; 
    margin-top: 40px; 
    display: block; 
  }
  

  .vertical-timeline {
    padding: 40px 20px;
    background-color: #f4f4f4;
    position: relative;
  }
  
  .timeline-container {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .timeline-item {
    position: relative;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .timeline-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .timeline-icon {
    position: absolute;
    top: 100%;
    left: -30px;
    transform: translateY(-50%);
    width: 80px;
    height: 80px;
    background-color: #333;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
  }
  
  .timeline-content {
    margin-left: 70px;
  }
  
  .timeline-content h3 {
    margin: 0 0 10px;
    font-size: 18px;
    color: #333;
  }
  
  .timeline-content p {
    margin: 0;
    color: #666;
  }
  
  .highlight {
    font-weight: bold;
    color: #fcba03;
    font-size: 50px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .timeline-container::before {
    content: '';
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #fcba03;
  }
  
  @media (max-width: 768px) {
    .timeline-container {
      padding: 20px;
    }
  
    .timeline-item {
      padding: 15px;
    }
  
    .timeline-icon {
      width: 40px;
      height: 40px;
      font-size: 16px;
    }
  
    .timeline-content h3 {
      font-size: 16px;
    }
  
    .timeline-content p {
      font-size: 14px;
    }
  }
  
  
  

  .brand-map {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .brand-image-container {
    width: 90%;
    display: flex;
    justify-content: center;
  }
  
  .brand-map-image {
    width: 100%;
    height: auto;
    object-fit: cover; 
  }


  .business-model-title {
    background-color: #f8cc4a;
    max-width: 100%;
    font-size: 70px;
    text-align: center; 
    display: flex;
    justify-content: center; 
    align-items: center; 
    margin: 0 auto; 
  }
  


  .business-model {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fcfcfcf6;
  }
  
  .business-model-container {
    width: 90%;
    overflow: hidden; 
    display: flex;
    justify-content: center;
    background-color: #fcfcfcf6;
  }
  
  .business-model-image {
    width: 100%;
    height: auto;
    object-fit: cover; 
    margin-top: 5%;
    margin-bottom: 5%;;
  }

  
  .material-matrix {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .material-matrix-container {
    width: 90%;
    overflow: hidden; 
    display: flex;
    justify-content: center;
  }
  
  .material-matrix-image {
    width: 100%;
    height: auto;
    object-fit: cover; 
    margin-top: 5%;
    margin-bottom: 5%;;
  }
  


  .product-matrix {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .product-matrix-container {
    width: 90%;
    overflow: hidden; 
    display: flex;
    justify-content: center;
  }
  
  .product-matrix-image {
    width: 100%;
    height: auto;
    object-fit: cover; 
    margin-top: 5%;
    margin-bottom: 5%;
  }
  


  .practices {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .practices-container {
    width: 90%;
    overflow: hidden; 
    display: flex;
    justify-content: center;
  }
  
  .practices-image {
    width: 100%;
    height: auto;
    object-fit: cover; 
    margin-top: 5%;
    margin-bottom: 5%;;
  }



  .team {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fcfcfcf6
  }
  
  .team-container {
    width: 100%;
    overflow: hidden; 
    display: flex;
    justify-content: center;
    background-color: #fcfcfcf6
  }
  
  .team-image {
    width: 80%;
    height: auto;
    object-fit: cover; 
    margin-top: 5%;
    margin-bottom: 5%;;
  }

  .map {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .map-container {
    width: 90%;
    overflow: hidden; 
    display: flex;
    justify-content: center;
  }
  
  .map-image {
    width: 100%;
    height: auto;
    object-fit: cover; 
    margin-top: 5%;
    margin-bottom: 5%;;
  }

  
  
  
  .performance-dashboard {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fcfcfcf6;
  }
  
  .performance-dashboard-container {
    width: 100%;
    overflow: hidden; 
    display: flex;
    justify-content: center;
    background-color: #fcfcfcf6;
  }
  
  .performance-dashboard-image {
    width: 100%;
    height: auto;
    object-fit: cover; 
    margin-top: 5%;
    margin-bottom: 5%;;
  }
  

  

  .chart-wrapper {
    margin: 0 20px;
    text-align: center;
    
  }
  
  .chart-title {
    font-size: 16px;
    font-weight: bold;
    color: #333; 
    margin-top: 10px;
  }
  
  
  .key-box {
    margin-left: 20px;
    padding: 10px;
    background: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .key-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .key-box li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .color-box {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
    border-radius: 4px;
  }
  
  
  .turnaround-section {
    padding: 20px;
    background-color: #fcfcfcf6;
    height: 90vh;
  }
  
  .turnaround-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
  }
  
  .turnaround-image {
    max-width: 70vh;
    height: auto;
    margin-left: 4%;
    margin-bottom: 2%;
  }
  
  .turnaround-text {
    max-width: 60%;
    font-size:20px;
  }
  
  .turnaround-text h2 {
    font-size: 34px;
    font-weight: bold;
    color: #00b0f0;
    margin-bottom: 10px;
  }
  
  .turnaround-text p {
    font-size: 26px;
    color: #666;
    margin-bottom: 10px;
  }

  
  .lead-time-container {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
  }
  
  .lead-time-label {
    font-size: 20px;
    color: #333;
    margin-right: 10px;
    font-weight: bold;
  }
  
  .lead-time-value {
    font-size: 52px;
    color: #00b0f0;
    font-weight: bold;
  }
