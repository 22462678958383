

.dropdown {
  position: relative;
  display: inline-block;
  position: fixed; 
  margin-top: 50px;
  top:0;
  right:0;
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  z-index: 1000; 
}

.dropdown-button {
  background-color: #000000c9; 
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.dropdown-menu {
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #000000c9;
  max-height: 70vh; 
  overflow-y: auto; 
  width: 200px; 
}

.dropdown-menu a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
}

.dropdown-menu a:hover {
  color: #fcba03;
}

@media (max-width: 600px) {
  .dropdown-menu {
    width: 100vw; 
    right: 0; 
  }
}